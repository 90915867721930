/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

/**
 * Internal dependencies.
 */
import WidgetLoad from 'components/widget-load/widget-load';
import WidgetPhoto from 'components/widget-photo/widget-photo';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '590px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px 36px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 -6px 41px'
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalCircle: {
		position: 'absolute',
		top: '-14px',
		left: '20px',
		width: '200px',
		height: '200px',
		borderWidth: '2px',
		[theme.breakpoints.down('xs')]: {
			position: 'relative',
			top: '0',
			left: '0',
			margin: '0 auto 20px'
		},
		'& .MuiButtonBase-root': {
			minWidth: '134px',
			fontSize: '11px',
			borderRadius: '9px',
			padding: '8px 10px',
			marginTop: '3px',
		}
	},
	modalActions: {
		margin: '40px -36px 0',
		padding: '27px 70px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			margin: '25px -30px 0',
			padding: '27px 30px 0',
			display: 'block',
		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	},
	modalContent: {
		position: 'relative',
		padding: '0 0 0 255px',
		margin: '0 0 93px',
		[theme.breakpoints.down('xs')]: {
			padding: '0',
			margin: '0 0 50px',
		}
	},
	modalText: {
		fontSize: '17px',
		lineHeight: '1.65',
		'& strong': {
			fontWeight: '700'
		}
	},
	modalSubtitle: {
		fontSize: '16px',
		fontWeight: '500',
		color: '#808191',
		marginBottom: '21px',
		'& strong': {
			fontWeight: '700'
		}
	},
	modalWidget: {
		margin: '0 -6px 26px'
	},
	modalButtonDisabled: {
		opacity: '.3'
	}
}));


const ModalAvatarError = ( { onClose, modifier } ) => {
	const classes = useStyles();

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalTitle}
				>Crear avatar</Typography>
			</Box>

			<WidgetLoad 
				modifier={classes.modalWidget}
				status="error"
			/>

			<Box className={classes.modalBody}>
				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalSubtitle}
				>Fotos de ejemplo</Typography>

				<Grid container spacing={2}>
					<Grid item md={4} sm={4} xs={6}>
						<WidgetPhoto 
							image={require('assets/images/user1.jpg')}	
							status="error"
						/>
					</Grid>

					<Grid item md={4} sm={4} xs={6}>
						<WidgetPhoto 
							image={require('assets/images/user2.jpg')}	
							status="error"
						/>
					</Grid>

					<Grid item md={4} sm={4} xs={6}>
						<WidgetPhoto 
							image={require('assets/images/user3.jpg')}	
							status="success"
						/>
					</Grid>
				</Grid>
			</Box>

			<Box className={classes.modalActions}>
				<Button 
					variant="outlined" 
					color="secondary"
					className={classes.modalButton + ' ' + classes.modalButtonDisabled}
					onClick={onClose}
				>Cancelar</Button>
				
				<Button 
					variant="contained" 
					color="secondary"
					className={classes.modalButton + ' ' + classes.modalButtonDisabled}
					onClick={onClose}
				>Guardar</Button>
			</Box>
		</Box>
	);
};

export default ModalAvatarError;