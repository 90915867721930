/**
 * External dependencies.
 */
import React, {useState} from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

/**
 * Internal dependencies.
 */
import LogoImage from 'assets/images/logo-alt.svg';
import ImageEye from 'assets/images/ico-eye.svg';

import { registerUser } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	form: {
		maxWidth: '402px',
		padding: '49px 48px',
		background: '#fff',
		color: '#2D333A',
		boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
		marginLeft: 'auto',
		borderRadius: '32px',
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%',
			width: 'calc(100vw - 60px)',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '20px',
		}
	},
	formHead: {
		textAlign: 'center',
		marginBottom: '24px',
	},
	formImage: {
		marginBottom: '22px',
	},
	formHint: {
		fontSize: '16px',
		letterSpacing: '0.15px',
		marginTop: '19px',
		[theme.breakpoints.down('sm')]:{
			fontSize: '14px',
		},
		'&>a': {
			fontSize: 'inherit',
			fontWeight: '600',
			color: '#00CCEE',
		}
	},
	formLink: {
		display: 'inline-block',
		fontSize: '16px',
		letterSpacing: '0.15px',
		color: '#00CCEE',
		marginTop: '13px'
	},
	formButton: {
		marginTop: '22px'
	},
	formIcon: {
		position: 'absolute',
		top: '50%',
		right: '13px',
		transform: 'translateY(-50%)',
		'& a': {
			color: '#00CCEE',
			letterSpacing: '0.15px',
			fontSize: '16px',
		}
	},
	formBox: {
		border: '1px solid #C2C8D0',
		padding: '14px 14px 6px',
		margin: '10px 0 0',
		fontFamily: 'Roboto, sans-serif',
	},
	formSubtitle: {
		fontFamily: 'Roboto, sans-serif',
		fontSize: '14px',
		fontWeight: '500',
		letterSpacing: '0.25px',
		margin: '0 0 8px',
	},
	formFeature: {
		paddingLeft: '23px !important',
		margin: '0 0 7px',
		'&:after': {
			content: '""',
			position: 'absolute',
			top: '46%',
			left: '0',
			transform: 'translateY(-50%)',
			width: '14px',
			height: '14px',
			borderRadius: '50%',
			border: '1px solid #C2C8D0',
		},
		'& span': {
			fontFamily: 'Roboto, sans-serif',
			letterSpacing: '0.25px',
		}
	},
	loadingAnimation: {
		animation: "spin 1s linear infinite"
	},
	errorMessage: {
		color: '#f50057'
	},
}));

async function submitCredentials(e, setErrorMessage, setLoading, classLoading) { 
	e.preventDefault()
	setLoading(classLoading);
	setErrorMessage("");

	try {
		await registerUser(document.getElementById("email-field").value, document.getElementById("name-field").value, document.getElementById("password-field").value, "", true);
	} catch (error) {
		setErrorMessage(error.message)
		setLoading("");
		return
	}
	//alert("Usuario admin registado con éxito")
	window.location.replace("/");
}

const FormSignupAlt = () => {
	const classes = useStyles();
	const [ errorMessage, setErrorMessage ] = useState("");
	const [showPassword, setShowPassword] = useState("password");
	const [loading, setLoading] = useState("");

	return (
		<Box className={classes.form} component="form" noValidate autoComplete="off">
			<Typography variant="h4" component="h4">El registro de administradores está deshabilitado.</Typography>
			<br></br>
			<Typography variant="h4" component="h4">Para crear un nuevo uusario administrador póngase en contacto con el equipo de La Frontera VR.</Typography>
			<Box className={classes.formHint}>¿Tienes ya una cuenta? <Link href="/">Inicia sesión</Link></Box>
		</Box>
	)

	return (
		<Box className={classes.form} component="form" noValidate autoComplete="off">
			<Box className={classes.formHead}>
				<img src={LogoImage} alt="logo" className={classes.formImage + loading}/>
				<style>{`
					@keyframes spin {
						0% { transform: rotate(0deg); }
						100% { transform: rotate(360deg); }
					}
				`}</style>

				<Typography variant="h4" component="h4">Registrarse como comercial</Typography>
			</Box>

			<Box className={classes.formBody}>
				<FormControl>
					<TextField 
						id="email-field" 
						label="Email" 
						variant="outlined"
						onKeyDown={e => {
							if (e.keyCode == 13 /*enter*/)
								document.getElementById("name-field").focus();
						}}
					/>

					<TextField 
						id="name-field" 
						label="Username" 
						variant="outlined"
						maxLength={15}
						onInput={(object) => {
							if (object.target.value.length > 15) {
								object.target.value = object.target.value.slice(0, 15)
							}
						}}
						onKeyDown={e => {
							if (e.keyCode == 13 /*enter*/)
								document.getElementById("password-field").focus();
						}}
					/>

					{/* <Box className={classes.formIcon}>
						<Link href="#">Edit</Link>
					</Box> */}
				</FormControl>

				<FormControl>
					<TextField 
						id="password-field" 
						label="Contraseña" 
						type={showPassword}
						autoComplete="current-password"
						variant="outlined"
						onKeyDown={(e) => {
							if (e.keyCode == 13 /*enter*/)
							submitCredentials(e, setErrorMessage, setLoading, " " + classes.loadingAnimation)
						}}
					/>

					<Box className={classes.formIcon} onMouseDown={e => setShowPassword("text")}
						onMouseUp={e => setShowPassword("password")}
					>
						<img src={ImageEye} alt="" />
					</Box>
				</FormControl>
				<Typography variant="subtitle1" className={classes.errorMessage}>{errorMessage}</Typography>

				{/* <Box className={classes.formBox}>
					<Typography variant="h6" component="h6" className={classes.formSubtitle}>Tu contraseña debe contener:</Typography>
					
					<List>
						<ListItem className={classes.formFeature}>
							<ListItemText primary="Al menos 8 caracteres" />
						</ListItem>
						
						<ListItem className={classes.formFeature}>
							<ListItemText primary="Letras minúsculas (a-z)" />
						</ListItem>

						<ListItem className={classes.formFeature}>
							<ListItemText primary="Letras mayúsculas (A-Z)" />
						</ListItem>

						<ListItem className={classes.formFeature}>
							<ListItemText primary="Números (0-9)" />
						</ListItem>
					</List>
				</Box> */}


				

				<Button className={classes.formButton} disabled={loading.length !== 0} variant="contained" color="primary" onClick={(e) => {submitCredentials(e, setErrorMessage, setLoading, " " + classes.loadingAnimation)}}>Crear nueva cuenta</Button>

				<Box className={classes.formHint}>¿Tienes ya una cuenta? <Link href="/">Inicia sesión</Link></Box>
			</Box>
		</Box>
	);
};

export default FormSignupAlt;