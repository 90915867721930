export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export async function requireAuthToken(withTestAPICall, redirectOnFail) {
  const authToken = getCookie("CirclesAuthToken");

  if (!authToken) {
    if (redirectOnFail) window.location.replace("/");
    return "";
  }

  if (withTestAPICall) {
    const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/room/${process.env.REACT_APP_GAMELIFT}`, {

      // Adding method type
      method: "GET",

      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": `Bearer ${authToken}`
      }
    });

    if (!response.ok) {
      setCookie("CirclesAuthToken", 0, 0);
      setCookie("CirclesUserID", 0, 0);
      setCookie("avatar", 0, 0);
      if (redirectOnFail) window.location.replace("/");
      return "";
    }
  }

  return authToken;
}

export function requireUserID(redirectOnFail) {
  const userID = getCookie("CirclesUserID");

  if (!userID) {
    setCookie("CirclesAuthToken", 0, 0);
    if (redirectOnFail) window.location.replace("/");
    return "";
  }

  return userID;
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function CheckValidFileType(filename, isAvatar, isLogoOrPoster = false) {
  //console.log("comprobando extension de " + filename)
  var ext = filename.match(/\.([^\.]+)$/)[1];
  switch (ext) {
    case 'png':
      return true;
    case 'jpg':
      return !isLogoOrPoster;
    case 'mp4':
    case 'mov':
      return !isAvatar && !isLogoOrPoster;
    case 'pdf':
    default:
      return false;
  }
};

export function AskForFile(event, isAvatar = false, isLogoOrPoster = false) {
  const fileList = event.target.files; /* now you can work with the file list */

  if (fileList.length > 0) {
    const selectedFile = fileList.item(0);

    //console.log(selectedFile.name);
    if (CheckValidFileType(selectedFile.name, isAvatar, isLogoOrPoster)) {
      return selectedFile;
    } else {
      throw new Error(`Error selecting file "${selectedFile.name}": type not supported. The supported file extensions are .png, .jpg, and .mp4`); // TODO and .pdf
    }
  } else {
    //console.log("No files");
    return false;
  }
}

export async function getProfileData(UserID) {
  const authToken = await requireAuthToken(false, false);

  const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${UserID}`, {
    // Adding method type
    method: "GET",

    // Adding headers to the request
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${authToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  //console.log(data);
  return data;
}

export async function performLogin(username, password) {
	const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login`, {
		method: "POST",
		body: JSON.stringify({
			GrantType: "Password",
			User: username,
			Password: password
		}),
		headers: {
			"Content-type": "application/json; charset=UTF-8"
		}
	});

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`);
	}

	const data = await response.json();
	return data;
}

export function postLogin(authToken, userId) {
	setCookie("CirclesAuthToken", authToken, 30);
	setCookie("CirclesUserID", userId, 30);
	window.location.replace("/circles");
}

export async function registerUser(email, username, password, creator, isAdmin) {
  let bodyObject = {
    Email: email,
    User: username,
    Password: password
  };

  if (isAdmin) {
    bodyObject.RoleId = "rol_9O9IzA4563efVl73";
  } else {
    bodyObject.App_Metadata = { Creator: creator }
  }

  //console.log(bodyObject);

  const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user`, {
    method: "POST",
    body: JSON.stringify(bodyObject),
    headers: {
      "Content-type": "application/json; charset=UTF-8"
    }
  });

  if (!response.ok) {
    const data = await response.json();
    const errorMessage = data.Error.Message;

    throw new Error( errorMessage ? errorMessage : `HTTP error! status: ${response.status}`);
  }

  const data = await response.json();

  //console.log(data);

  // ----------- Set creator metadata to its own id -----------
  if (isAdmin) {
    const loginData = await performLogin(username, password);

    // Edit user's App_Metadata
    const responseMetadata = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${data.user_id}`, {
      method: "POST",
      body: JSON.stringify({
        App_Metadata: { Creator: data.user_id }
      }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": `Bearer ${loginData.Id_token}`
      }
    });

    if (!responseMetadata.ok) {
      throw new Error(`HTTP error! status: ${responseMetadata.status}`);
    }

    const dataMetadata = await responseMetadata.json();

    //console.log(dataMetadata);
    postLogin(loginData.Id_token, data.user_id);
  }

}

export async function getMyUsers() {
  const authToken = await requireAuthToken(false, false);
  const profileData = await getProfileData(await requireUserID(true));
  let params = "";
  try {
    const metadata = getAppMetadataValue(profileData, "Creator");
    if (metadata) {
      params = `?query=app_metadata.Creator:"${metadata}"`;
    }
  } catch (error) {
    console.error(error);
  }

  const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user${params}`, {
    // Adding method type
    method: "GET",

    // Adding headers to the request
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${authToken}`
    }
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  return data;
}

export function getAppMetadataValue(profileData, key) {
  let returnValue;

  try {
    returnValue = profileData.app_metadata.find((currentValue, index, arr) => currentValue.Key === key).Value;
  } catch (error) {
    return ""
  }
  return returnValue;
}

export function logout() {
  setCookie("CirclesAuthToken", 0, 0);
  setCookie("CirclesUserID", 0, 0);
  setCookie("avatar", 0, 0);
  window.location.replace("/");
}

export async function submitAvatar(event, userId) {
  const selectedFile = AskForFile(event, true);

  if (selectedFile) {
    const authToken = await requireAuthToken(false, true);

    const responsePresignedURL = await fetch(`${process.env.REACT_APP_ENDPOINT}/avatar/${userId}`, {
      // Adding method type
      method: "PUT",

      // Adding headers to the request
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        "Authorization": `Bearer ${authToken}`
      }
    });

    if (!responsePresignedURL.ok) {
      throw new Error(`HTTP error! status: ${responsePresignedURL.status}`);
    }

    const data = await responsePresignedURL.json();

    //console.log(data);

    if ("Error" in data) {
      throw new Error(`Error getting presigned URL: ${data.Error.Message}`);
    }

    if (!data.UploadUrl) {
      throw new Error(`Error getting presigned URL: no upload URL`);
    }

    let headersList = {
      "Accept": "*/*",
      "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      "Origin": "https://fake.com" //TODO Change this to our URL
    };

    const responseUpload = await fetch(data.UploadUrl, {
      method: "PUT",
      body: selectedFile,
      headers: headersList
    });

    if (!responseUpload.ok) {
      throw new Error(`HTTP error PUT to avatar presigned url! status: ${responseUpload.status}`);
    }

    //console.log("All good");

    const responseGenerateAvatar = await fetch(`${process.env.REACT_APP_ENDPOINT}/avatar/${userId}`, {
      // Adding method type
      method: "POST",

      // Adding headers to the request
      headers: {
        "Accept": "application/json; charset=UTF-8",
        "Authorization": `Bearer ${authToken}`
      }
    });

    if (!responseGenerateAvatar.ok) {
      throw new Error(`HTTP error generating avatar! status: ${responseUpload.status}`);
    }

    window.location.reload();
  }
}

export async function uploadMedia(file, collectionPath = "", overrideName = null) {
  const authToken = await requireAuthToken(false, true);
  const URLParams = collectionPath ? ("?fromCollection=" + collectionPath) : "";

  const responsePresignedURL = await fetch(`${process.env.REACT_APP_ENDPOINT}/media/${overrideName ?? file.name}${URLParams}`, {
    method: "PUT",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      "Authorization": `Bearer ${authToken}`
    }
  });

  if (!responsePresignedURL.ok) {
    throw new Error(`HTTP error! status: ${responsePresignedURL.status}`);
  }

  const data = await responsePresignedURL.json();

  //console.log(data);

  if ("Error" in data) {
    throw new Error(`Error getting presigned URL: ${data.Error.Message}`);
  }

  if (!data.UploadUrl) {
    throw new Error(`Error getting presigned URL: no upload URL`);
  }

  let headersList = {
    "Accept": "*/*",
    "User-Agent": "Thunder Client (https://www.thunderclient.com)",
    "Content-Type": file.type,
    "Origin": "https://fake.com" //TODO Change this to our URL
  };

  const responseUpload = await fetch(data.UploadUrl, {
    method: "PUT",
    body: file,
    headers: headersList
  });

  if (!responseUpload.ok) {
    throw new Error(`HTTP error! status: ${responseUpload.status}`);
  }
}

export async function fetchMedia(collectionPath = "") {
	const authToken = await requireAuthToken(false, true);
	const URLParams = collectionPath ? ("?fromCollection=" + collectionPath) : "";
	const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/media${URLParams}`, {
		// Adding method type
		method: "GET",

		// Adding headers to the request
		headers: {
			"Content-type": "application/json; charset=UTF-8",
			"Authorization": `Bearer ${authToken}`
		}
	});

	if (!response.ok) {
		throw new Error(`HTTP error! status: ${response.status}`); // handle errors
	}

	const data = await response.json();
	return data;
}

export function getIconByType(type) {
	if (type.startsWith('video'))
		return (<i className="ico-movie"></i>);
	else if (type.startsWith('image'))
		return (<i className="ico-image"></i>);
	else
		return (<i className="ico-pdf"></i>);
}
