/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';
import Modal from '@material-ui/core/Modal';
import Skeleton from '@mui/material/Skeleton';

/**
 * Internal dependencies.
 */
import WidgetEdit from 'components/widget-edit/widget-edit';
import ModalDeleteUser from 'components/modal-delete-user/modal-delete-user';
import { getProfileData, requireAuthToken, submitAvatar } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 42px',
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalField: {
		marginBottom: '21px',
		'& input': {
			fontSize: '18px',
			fontWeight: '500',
			letterSpacing: '0.5px',
			height: '44px',
			border: 'none',
			background: '#F0F0F3',
			fontFamily: 'Poppins, sans-serif',
			color: '#323338',
			'&::-webkit-input-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
			'&::-moz-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
			'&:-moz-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
			'&:-ms-input-placeholder': {
				color: '#323338',
				opacity: '1',
				fontWeight: '500'
			},
		}
	},
	modalCircle: {
		width: '200px',
		height: '200px',
		borderWidth: '2px',
		margin: '0 auto 29px',
		[theme.breakpoints.down('xs')]: {
			margin: '0 auto 29px'
		}
	},
	modalActions: {
		margin: '35px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			margin: '25px -30px 0',
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}

	},
	modalButtonDisabled: {
		opacity: '.3'
	},
	modalLink: {
		padding: '0',
		color: '#00CCEE',
		fontSize: '18px',
		letterSpacing: '0.5px',
		[theme.breakpoints.down('xs')]: {
			margin: '0 0 10px',
		}
	},
	modalLinks: {
		display: 'flex',
		justifyContent: 'space-between',
		margin: '31px 0 0',
		[theme.breakpoints.down('xs')]:{
			display: 'block',
			margin: '20px 0 0',
		}
	},
	skeletonStyle: {
		minWidth: '100% !important'
	},
	subtitleStyle: {
		marginBottom: '1em'
	}
}));


const ModalEditUser = ( { userId, onClose, reload, modifier } ) => {
	const classes = useStyles();
	const [ openInnerModal, setOpenInnerModal ] = useState(false);
	const [ name, setName ] = useState("");
	const [ email, setEmail ] = useState("");
	const [ image, setImage ] = useState("");
	const [ disabled, setDisabled ] = useState(false);

	useEffect(() => {
		async function fetchProfile() {
			const data = await getProfileData(userId);

			setName(data.username);
			setEmail(data.email);
			setImage(data.picture);
		};

		fetchProfile();
	}, []);



	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography 
					variant="h4" 
					component="h4"
					className={classes.modalTitle}
				>Editar perfil</Typography>
			</Box>

			<Box className={classes.modalBody}>
				<WidgetEdit 
					modifier={classes.modalCircle}	
					image={image}
					accept='.png, .jpg'
					onChange={(e) => submitAvatar(e, userId)}
				/>

				{(name) ? <Typography
				variant="subtitle1"
				className={classes.subtitleStyle}
				>La imagen de perfil se usará para generar el avatar realista</Typography> : 
				<Skeleton animation='wave' variant="text" className={classes.skeletonStyle}><Typography variant="subtitle1" className={classes.subtitleStyle}
				>La imagen de perfil se usará para generar el avatar realista</Typography></Skeleton>}
				

				{(name) ? <Input id="username-field" disabled={disabled} defaultValue={name} className={classes.modalField}/> : <Skeleton animation='wave' variant="text" className={classes.skeletonStyle}><Input id="username-field" defaultValue={name} className={classes.modalField} /></Skeleton>}
				{(email) ? <Input id="email-field" disabled={disabled} defaultValue={email} className={classes.modalField}/> : <Skeleton animation='wave' variant="text" className={classes.skeletonStyle}><Input id="email-field" defaultValue={name} className={classes.modalField} /></Skeleton>}
				

				<Box className={classes.modalLinks}>
					{/* <Button TODO implement
						variant="text" 
						className={classes.modalLink}
					>Reenviar código</Button> */}

					<Button 
						variant="text" 
						className={classes.modalLink}
						onClick={(e) => { setOpenInnerModal(true) }}
					>Eliminar usuario</Button>
				</Box>
			</Box>

			<Box className={classes.modalActions}>
				<Button 
					variant="outlined" 
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Cancelar</Button>
				
				<Button 
					variant="contained" 
					color="secondary"
					className={classes.modalButton}
					disabled={disabled}
					onClick={(e) => { 
						setDisabled(true)
						submitChanges();

						async function submitChanges() {
							const authToken = await requireAuthToken(false, true);

							const username_field = document.getElementById("username-field").value;
							const email_field = document.getElementById("email-field").value;

							if (name !== username_field) {
								const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${userId}`, {
									method: "POST",
									body: JSON.stringify({
										Username: username_field
									}),
									headers: {
										"Content-type": "application/json; charset=UTF-8",
										"Authorization": `Bearer ${authToken}`
									}
								});

								if (!response.ok) {
									setDisabled(false);
									throw new Error(`HTTP error! status: ${response.status}`);
								}

								const data = await response.json();

								//console.log(data);

								if ("Error" in data) {
									setDisabled(false);
									throw new Error(`Error creating room: ${data.Error.Message}`);
								}
							}

							if (email !== email_field) {
								const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${userId}`, {
									method: "POST",
									body: JSON.stringify({
										Email: email_field
									}),
									headers: {
										"Content-type": "application/json; charset=UTF-8",
										"Authorization": `Bearer ${authToken}`
									}
								});

								if (!response.ok) {
									setDisabled(false);
									throw new Error(`HTTP error! status: ${response.status}`);
								}

								const data = await response.json();

								//console.log(data);

								if ("Error" in data) {
									setDisabled(false);
									throw new Error(`Error creating room: ${data.Error.Message}`);
								}
							}

							if (reload) window.location.reload();

							onClose();
						}

					 }}
				>Guardar</Button>
			</Box>

			<Modal
				open={openInnerModal}
				onClose={(e) => { setOpenInnerModal(false) }}
			>
				<div><ModalDeleteUser username={name} userId={userId} onClose={(e) => { setOpenInnerModal(false) }}/></div>
			</Modal>
		</Box>
	);
};

export default ModalEditUser;