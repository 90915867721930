/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
//import { useCookies } from 'react-cookie';

/**
 * Internal dependencies.
 */
import LogoImage from 'assets/images/logo-alt.svg';
import ImageEye from 'assets/images/ico-eye.svg';
import { performLogin, postLogin } from 'MyUtils.js'


const useStyles = makeStyles(theme => createStyles({
	form: {  
		maxWidth: '402px',
		padding: '49px 48px',
		background: '#fff',
		color: '#2D333A',
		boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
		marginLeft: 'auto',
		borderRadius: '32px',
		[theme.breakpoints.down('md')]: {
			maxWidth: '100%',
			width: 'calc(100vw - 60px)',
		},
		[theme.breakpoints.down('sm')]: {
			padding: '20px',
		}
	},
	formHead: {
		textAlign: 'center',
		marginBottom: '24px',
	},
	formImage: {
		marginBottom: '22px',
	},
	formHint: {
		fontSize: '16px',
		letterSpacing: '0.15px',
		marginTop: '19px',
		[theme.breakpoints.down('sm')]:{
			fontSize: '14px',
		},
		'&>a': {
			fontSize: 'inherit',
			fontWeight: '600',
			color: '#00CCEE',
		}
	},
	formLink: {
		display: 'inline-block',
		fontSize: '16px',
		letterSpacing: '0.15px',
		color: '#00CCEE',
		marginTop: '13px'
	},
	formButton: {
		marginTop: '22px'
	},
	formIcon: {
		position: 'absolute',
		top: '50%',
		right: '13px',
		transform: 'translateY(-50%)',
	},
	formDevider: {
		position: 'relative',
		margin: '28px 0 32px',
		height: '1px',
		background: '#C2C8D0',
		'&:after': {
			content: '"O"',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			fontSize: '12px',
			lineHeight: '1',
			width: '30px',
			height: '30px',
			background: '#fff',
			textAlign: 'center',
			margin: '9px 0 0',
			zIndex: '5'
		}
	},
	loadingAnimation: {
		animation: "spin 1s linear infinite"
	},
	errorMessage: {
		color: '#f50057'
	}
}));

async function submitCredentials(e) { 
	e.preventDefault()
	
	const data = await performLogin(document.getElementById("email-field").value, document.getElementById("password-field").value);

	//console.log(data)

	// Check is admin
	const responseRole = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/roles/${data.UserID}`, {
		method: "GET",
		headers: {
			"Accept": "application/json",
			"Authorization": `Bearer ${data.Id_token}`
		}
	});

	if (!responseRole.ok) {
		throw new Error(`HTTP error getting roles! status: ${responseRole.status}`); // handle errors
	}

	const dataRole = await responseRole.json();

	//console.log(dataRole)

	if (dataRole.Roles.length == 0) {
		//alert("Solo los usuarios administradores tienen acceso a Circles"); por algun motivo si borro esta linea no va
		throw new Error(`Solo los usuarios administradores tienen acceso a Circles`); // handle errors
	}

	postLogin(data.Id_token, data.UserID);
}


const FormSignup = () => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState("password");
	const [ errorMessage, setErrorMessage ] = useState("");
	const [loading, setLoading] = useState("");

	return (
		<Box className={classes.form} component="form" noValidate autoComplete="off">
			<Box className={classes.formHead}>
				<img src={LogoImage} alt="logo" className={classes.formImage + loading} />
				<style>{`
					@keyframes spin {
						0% { transform: rotate(0deg); }
						100% { transform: rotate(360deg); }
					}
				`}</style>

				<Typography variant="h4" component="h4">Iniciar sesión</Typography>
			</Box>

			<Box className={classes.formBody}>
				<FormControl>
					<TextField 
						id="email-field" 
						label="Nombre de usuario" 
						variant="outlined"
						onKeyDown={e => {
							if (e.keyCode == 13 /*enter*/)
								document.getElementById("password-field").focus();
						}}
					/>
				</FormControl>

				<FormControl>
					<TextField 
						id="password-field" 
						label="Contraseña" 
						type={showPassword}
						autoComplete="current-password"
						variant="outlined"
						onKeyDown={async e => {
							if (e.keyCode == 13 /*enter*/ && document.getElementById("email-field").value && document.getElementById("password-field").value) {
								setErrorMessage("");
								setLoading(" " + classes.loadingAnimation);
								try {
									await submitCredentials(e)
								} catch (error) {
									setLoading("");
									if (error.message !== `Solo los usuarios administradores tienen acceso a Circles`)
										setErrorMessage("Usuario o contraseña incorrectos.");
								}
							}
						}}
					/>

					<Box className={classes.formIcon} onMouseDown={e => setShowPassword("text")}
						onMouseUp={e => setShowPassword("password")}
					>
						<img src={ImageEye} alt="" />
					</Box>
				</FormControl>

				{/* <Link className={classes.formLink} href="#">¿Olvidaste la contraseña?</Link> */}
				<Typography variant="subtitle1" className={classes.errorMessage}>{errorMessage}</Typography>

				<Button 
					className={classes.formButton} 
					variant="contained" 
					color="primary" 
					disabled={loading.length != 0}
					onClick={async e => {
						setErrorMessage("");
						setLoading(" " + classes.loadingAnimation);
						try {
							await submitCredentials(e)
						} catch (error) {
							setLoading("");
							if (error.message !== `Solo los usuarios administradores tienen acceso a Circles`)
								setErrorMessage("Usuario o contraseña incorrectos.");
							else
								setErrorMessage("Solo los usuarios administradores tienen acceso a Circles");
						}
					}}
				>
					Entrar
				</Button>

				{/* <Box className={classes.formHint}>¿No tienes una cuenta? <Link href='/signup-alt'>Regístrate</Link></Box> */}
			</Box>
		</Box>
	);
};

export default FormSignup;