/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';

/**
 * Internal dependencies.
 */
import User from 'components/user/user';
import ModalDelete from 'components/modal-delete/modal-delete';

import { getAppMetadataValue, getMyUsers, requireUserID, requireAuthToken } from 'MyUtils';

const useStyles = makeStyles(theme => createStyles({
	modal: {
		width: '500px',
		maxHeight: 'calc(100vh - 60px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		background: '#fff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		padding: '30px',
		[theme.breakpoints.down('xs')]: {
			width: 'calc(100% - 60px)'
		}
	},
	modalHead: {
		position: 'relative',
		padding: '3px 0',
		margin: '0 0 49px',
	},
	modalBody: {
		position: 'relative',
		padding: '0 10px',
		maxHeight: '392px',
		overflowY: 'auto',
		overflowX: 'hidden',
		[theme.breakpoints.down('xs')]: {
			padding: '0',
			maxHeight: '193px',
		},
		'&::-webkit-scrollbar': {
			width: '6px'
		},
		'&::-webkit-scrollbar-track': {
			width: '6px',
			background: '#E6E9EF',
			borderRadius: '20px',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '20px',
			background: '#808191',
		}
	},
	modalTitle: {
		fontWeight: '600',
	},
	modalClose: {
		position: 'absolute',
		top: '50%',
		right: '-10px',
		transform: 'translateY(-50%)',
		border: 'none',
		background: '#E6E9EF',
		width: '48px',
		height: '48px',
		borderRadius: '50%',
		'& i': {
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)'
		}
	},
	modalActions: {
		margin: '35px -30px 0',
		padding: '27px 24px 0',
		borderTop: '1px solid #E6E9EF',
		display: 'flex',
		justifyContent: 'space-between',
		[theme.breakpoints.down('xs')]: {
			display: 'block',

		}
	},
	modalButton: {
		flex: '0 0 calc(50% - 30px)',
		maxWidth: '50%',
		minWidth: '218px',
		margin: '0 !important',
		[theme.breakpoints.down('xs')]: {
			maxWidth: '100%',
			width: '100%',
			minWidth: '0',
		},
		'& + .MuiButtonBase-root': {
			[theme.breakpoints.down('xs')]: {
				marginTop: '20px !important'
			}
		}
	}
}));

const users = [
	{
		avatar: require('assets/images/av5.jpg'),
		name: "Tú",
		checked: false,
		admin: true
	},
	{
		avatar: require('assets/images/av2.jpg'),
		name: "Susana Suárez Sánchez",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av3.jpg'),
		name: "Adrián Álvarez Pérez",
		checked: true,
		admin: false
	},
	{
		avatar: require('assets/images/av1.jpg'),
		name: "Miguel Martínez González",
		checked: false,
		admin: false
	},
	{
		avatar: "",
		name: "Sara García",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av4.jpg'),
		name: "Santiago Rodríguez",
		checked: true,
		admin: false
	},
	{
		avatar: "",
		name: "Marina Ortega",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av2.jpg'),
		name: "Susana Suárez Sánchez",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av3.jpg'),
		name: "Adrián Álvarez Pérez",
		checked: true,
		admin: false
	},
	{
		avatar: require('assets/images/av1.jpg'),
		name: "Miguel Martínez González",
		checked: false,
		admin: false
	},
	{
		avatar: "",
		name: "Sara García",
		checked: false,
		admin: false
	},
	{
		avatar: require('assets/images/av4.jpg'),
		name: "Santiago Rodríguez",
		checked: true,
		admin: false
	},
	{
		avatar: "",
		name: "Marina Ortega",
		checked: false,
		admin: false
	}
]

const ModalGuests = ({ onClose, modifier, roomId }) => {
	const classes = useStyles();
	const [openInnerModal, setOpenInnerModal] = useState(false);
	const [modifiedAccess, setModifiedAccess] = useState([]);
	const [users, setUsers] = useState(() => {
		let aux = [];
		for (let i = 0; i < 6; i++) {
			aux.push({
				avatar: "loading.jpg",
				name: "loading",
				checked: true,
				admin: true,
				skeleton: true
			});
		}
		return aux;
	});


	useEffect(() => {
		async function fetchUsers() {
			const data = await getMyUsers();

			//console.log(data)

			if (data.UsersList.length > 0) {
				let auxUsers = [];
				setUsers([]);
				data.UsersList.forEach(element => {
					//console.log(element);
					//const isInRoom = Boolean(element.app_metadata.find((currentValue, index, arr) => currentValue.Key === "Creator").Value);
					const isInRoom = Boolean(getAppMetadataValue(element, roomId));
					const isAdmin = element.user_id === requireUserID(true);
					//console.log(`isInRoom ${isInRoom}, isAdmin ${isAdmin}`);
					const userObject = {
						name: element.username,
						avatar: element.picture,
						admin: isAdmin,
						checked: isInRoom,
						user_id: element.user_id,
						skeleton: false
					};

					auxUsers.push(userObject);
				});
				//auxUsers.sort((a, b) => a.name > b.name)
				setUsers(auxUsers);
			}
		};

		fetchUsers();
	}, [])

	const handleChange = e => {
		const { value, checked } = e.target;
		const filteredArray = modifiedAccess.filter(x => x.userId !== value);

		if (filteredArray.length == modifiedAccess.length) {
			setModifiedAccess(prev => [...prev, { userId: value, hasAccess: checked }]);
			//console.log("added to list");
		} else {
			setModifiedAccess(filteredArray);
			//console.log("deleted from list");
		}
		//console.log(modifiedAccess);
	}

	return (
		<Box className={classes.modal + ' ' + modifier}>
			<Box className={classes.modalHead}>
				<button className={classes.modalClose} onClick={onClose}>
					<i className="ico-x"></i>
				</button>

				<Typography
					variant="h4"
					component="h4"
					className={classes.modalTitle}
				>Invitados</Typography>
			</Box>

			<Box className={classes.modalBody}>
				{users?.map((user, index) => {
					return (
						<User
							key={index}
							name={user.name}
							avatar={user.avatar}
							checked={user.checked}
							admin={user.admin}
							userId={user.user_id}
							changeFunction={handleChange}
							skeleton={user.skeleton}
						/>
					)
				})}
			</Box>

			<Box className={classes.modalActions}>
				<Button
					variant="outlined"
					color="secondary"
					className={classes.modalButton}
					onClick={onClose}
				>Cancelar </Button>

				<Button
					variant="contained"
					color="secondary"
					className={classes.modalButton}
					disabled={modifiedAccess.length <= 0}
					onClick={(e) => {
						submitChanges();

						async function submitChanges() {
							const authToken = await requireAuthToken(false, true);

							modifiedAccess.forEach(async change => {
								const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/login/user/${change.userId}`, {
									method: "POST",
									body: JSON.stringify({
										App_Metadata: { [roomId]: change.hasAccess }
									}),
									headers: {
										"Content-type": "application/json; charset=UTF-8",
										"Authorization": `Bearer ${authToken}`
									}
								});

								if (!response.ok) {
									throw new Error(`HTTP error! status: ${response.status}`);
								}

								const data = await response.json();

								//console.log(data);

								if ("Error" in data) {
									throw new Error(`Error creating room: ${data.Error.Message}`);
								}
							});
							onClose();
						}
					}}
				>Guardar</Button>
			</Box>

			<Modal
				open={openInnerModal}
				onClose={(e) => { setOpenInnerModal(false) }}
			>
				<div><ModalDelete onClose={(e) => { setOpenInnerModal(false) }} /></div>
			</Modal>
		</Box>
	);
};

export default ModalGuests;