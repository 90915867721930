/**
 * Internal dependencies
 */
import Routes from 'blocks/routes/routes';

const  App = () => {
	return (
		<Routes />
	);
}

export default App;
