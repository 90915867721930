/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import Header from 'blocks/header/header';
import Footer from 'blocks/footer/footer';

const LayoutBase = ({ children }) => (
	<div className="wrapper wrapper--base">
		<Header />
		
		{children}

		<Footer />
	</div>
);

export default LayoutBase;
