/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';
import WidgetInfo from 'components/widget-info/widget-info';
import MediaTabs from 'components/media-tabs/media-tabs';
import ArticleDef from 'components/article-def/article-def';

import MediaImage from 'assets/images/media.jpg';

const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '159px 0 17px',
		[theme.breakpoints.down('md')]: {
			
		},
		[theme.breakpoints.down('xs')]: {
			
		}
	},
	
}));

const articles = [
	{
		image: require('assets/images/media.jpg'),
		title: "Presentación La Front...",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media1.jpg'),
		title: "PresentacionLFVR-v1",
		type: "pdf",
		info: "PDF · 2,8 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "image",
		info: "PNG · 129 KB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	}
]
const MediaCover = () => {
	const classes = useStyles();

	return (
		<LayoutAlt activeNav="media">
			<Box className={classes.section}>
				<Container maxWidth="lg">
					<WidgetInfo />

					<MediaTabs>
						<Grid container spacing="3">
							{/* <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
								<ArticleDef
									image={MediaImage}
									title="Presentación La Front..."
									type="mp4"
									info="MP4 · 8,2 MB"
									url="/"
								/>
							</Grid> */}

							{articles?.map((article, index) => {
								return (
									<Grid key={index} item xl={3} lg={3} md={4} sm={6} xs={12}>
										<ArticleDef
											image={article.image}
											title={article.title}
											type={article.type}
											info={article.info}
											url={article.url}
										/>
									</Grid>
								) 
							})}
						</Grid>
					</MediaTabs>
				</Container>
			</Box>
		</LayoutAlt>
	);
};

export default MediaCover;
