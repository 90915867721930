/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link  from '@material-ui/core/Link';
import Cookies from 'blocks/cookies/cookies';

/**
 * Internal dependencies.
 */
import LogoImage from 'assets/images/logo-def.svg';
import Frontera from 'assets/images/frontera.svg';

const useStyles = makeStyles(theme => createStyles({
	footer: {
		background: '#323338',
		color: '#fff',
		position: 'relative',
		zIndex: '10',
		padding: '15px 0',
		[theme.breakpoints.down('md')]: {
			
		},
	},
	footerInner: {
		margin: '0 -10px 0 -31px',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		[theme.breakpoints.down('lg')]: {
			margin: '0 0 0 -5px',
		},
		'& > a': {
			fontSize: '12px',
			color: 'inherit',
			fontWeight: '500',
			display: 'inline-flex',
			alignItems: 'center',
			'& img': {
				[theme.breakpoints.down('xs')]: {
					maxWidth: '100px'
				}
			}
		},
		'& img': {
			marginLeft: '16px',
			[theme.breakpoints.down('lg')]: {
				marginLeft: '5px',
			}
		}
	}
 }));

const Footer = () => {
	const classes = useStyles();

	return (
		<Box className={classes.footer}>
			<Container maxWidth={false}>
				<Box className={classes.footerInner}>
					<Link href="#">
						<img src={LogoImage} alt="" />
					</Link>

					<Link href="https://lafronteravr.com/">
						by <img src={Frontera} alt="" />
					</Link>
				</Box>
				
			</Container>

			<Cookies />
		</Box>
	);
};

export default Footer;
