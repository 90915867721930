import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 767,
			md: 1023,
			lg: 1439,
			xl: 1919,
		},
	},
})

theme.overrides = {
	MuiAppBar: {
		root: {
			padding: '42px 0',
			boxShadow: 'none'
		},
		colorPrimary: {
			backgroundColor: 'transparent',
			color: '#808191',
		}
	},
	MuiContainer: {
		root: {
			padding: '0 55px !important',
			[theme.breakpoints.down('lg')]: {
				padding: '0 30px !important',
			}
		},
		maxWidthXl: {
			maxWidth: '1580px !important'
		},
		maxWidthLg: {
			maxWidth: '1656px !important'
		}
	},
	MuiTypography: {
		root: {
			fontFamily: 'Poppins, sans-serif',
			fontSize: '32px',
			lineHeight: '1.75',
			fontWeight: '400',
			letterSpacing: '0.04em'
		},
		body1: {
			fontFamily: 'Poppins, sans-serif',
			fontSize: '32px',
			lineHeight: '1.75',
			fontWeight: '400',
			letterSpacing: '0.04em',
			[theme.breakpoints.down('lg')]: {
				fontSize: '24px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px',
			}
		},
		body2: {
			fontFamily: 'Poppins, sans-serif',
			fontSize: '18px',
			lineHeight: '2',
			[theme.breakpoints.down('lg')]: {
				fontSize: '18px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px',
			}
		},
		h1 : {
			fontFamily: 'Poppins, sans-serif',
			fontSize: '100px',
			lineHeight: '1.3',
			fontWeight: '500',
			letterSpacing: '0.5px',
			marginBottom: '29px',
			[theme.breakpoints.down('lg')]: {
				fontSize: '76px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '43px',
			}
		},
		h4 : {
			fontFamily: 'Poppins, sans-serif',
			fontSize: '24px',
			lineHeight: '1',
			fontWeight: '400',
			letterSpacing: '0.18px'
		},
		h5 : {
			fontFamily: 'Poppins, sans-serif',
			fontSize: '18px',
			fontWeight: '500',
			letterSpacing: '0.5px',
			[theme.breakpoints.down('xs')]: {
				fontSize: '11px'
			}
		},
	},
	MuiInputBase: {
		root: {
			display: 'block',
			fontSize: '16px',
			letterSpacing: '0',
			'&:after': {
				display: 'none'
			},
			'&:before': {
				display: 'none'
			},
		},
		input: {
			color: '#2D333A',
			border: '1px solid #C2C8D0',
			height: '56px',
			borderRadius: '8px',
			padding: '0 15px',
			boxSizing: 'border-box',
			'&::-webkit-input-placeholder': {
				color: '#858585'
			},
			'&::-moz-placeholder': {
				color: '#858585'
			},
			'&:-moz-placeholder': {
				color: '#858585'
			},
			'&:-ms-input-placeholder': {
				color: '#858585'
			},
		}
	},
	MuiOutlinedInput: {
		root: {
			'&.Mui-focused': {
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: '#00CCEE'
				}
			},
		}
	},
	MuiFormLabel: {
		root: {
			fontFamily: 'Poppins, sans-serif',
			fontSize: '16px',
			'&.Mui-focused': {
				color: '#00CCEE'
			},
		}
	},
	MuiButton: {
		root: {
			fontFamily: 'Poppins, sans-serif',
			letterSpacing: '0',
			textTransform: 'none',
			fontSize: '16px',
		},
		contained: {
			boxShadow: 'none'
		},
		containedPrimary: {
			width: '100%',
			padding: '12px 10px',
			color: '#fff',
			backgroundColor: '#00CCEE',
			borderRadius: '16px',
			fontWeight: '700',
		},
		containedSecondary: {
			background: 'radial-gradient(100% 100% at 0% 50%, #0C55ED 0%, #00CCEE 100%)',
			padding: '12px 5px',
			fontWeight: '600',
			fontSize: '18px',
			letterSpacing: '0.5px',
			minWidth: '218px',
			borderRadius: '18px',
		},
		outlined: {
			width: '100%',
			border: '1px solid #C2C8D0',
			color: '#2D333A',
			padding: '10px 10px 10px 54px',
			borderRadius: '0',
			fontWeight: '300',
			justifyContent: 'flex-start',
			'& img': {
				position: 'absolute',
				top: '50%',
				left: '20px',
				transform: 'translate(0, -50%)',
			},
			'& + button': {
				marginTop: '10px'
			}
		},
		outlinedSecondary: {
			border: '2px solid #00CCEE',
			borderRadius: '16px',
			color: '#00CCEE',
			fontSize: '18px',
			fontWeight: '600',
			justifyContent: 'center',
			padding: '10px 10px',
		},
		text: {
			color: '#808191',
			fontWeight: '600',
			fontSize: '20px',
			'& i' : {
				marginLeft: '9px'
			}
		}
	},
	MuiFormControl: {
		root: {
			display: 'flex',
			'& + .MuiFormControl-root': {
				marginTop: '25px'
			}
		}
	},
	MuiList: {
		padding: {
			padding: '0 !important'
		},
	},
	MuiListItem: {
		root: {
			padding: '0 !important'

		}
	},
	MuiListItemText: {
		root: {
			margin: '0 !important',
			'& span': {
				fontSize: '14px !important'
			}			
		}
	},
	MuiTabs: {
		root: {
			minHeight: '0'
		}
	},
	MuiTab: {
		root: {
			padding: '0',
			fontSize: '24px',
			minHeight: '0',
			fontFamily: 'Poppins, sans-serif',
			fontWeight: '600',
			letterSpacing: '0.5px',
			textTransform: 'none',
			minWidth: '0 !important',
			'& + a': {
				marginLeft: '94px'
			}
		}
	},
	MuiAvatar: {
		root: {
			width: '60px',
			height: '60px',
			[theme.breakpoints.down('xs')]: {
				width: '36px',
				height: '36px',
			}
		}
	},
	MuiCheckbox: {
		colorSecondary: {
			'&.Mui-disabled': {
				color: '#808191 !important'
			},
			'&.Mui-checked': {
				color: '#00CCEE'
			}
		}
	}
}

export default theme;