/**
 * External dependencies.
 */
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

/**
 * Internal dependencies.
 */
import Home from 'pages/home';
import Signup from 'pages/signup';
import SignupAlt from 'pages/signup-alt';
import SignupAltValid from 'pages/signup-alt-valid';
import Circles from 'pages/circles';
import CirclesCover from 'pages/circles-cover';
import Users from 'pages/users';
import UsersCover from 'pages/users-cover';
import Popups from 'pages/popups';
import Media from 'pages/media';
import MediaCover from 'pages/media-cover';
import MediaScroll from 'pages/media-scroll';

const Routes = () => (
	<Router>
		<Switch>
			<Route
				path="/"
				exact
				component={Home}
			/>

			<Route
				path="/signup"
				exact
				component={Signup}
			/>

			<Route
				path="/signup-alt"
				exact
				component={SignupAlt}
			/>

{/* 			<Route
				path="/signup-alt-valid"
				exact
				component={SignupAltValid}
			/>
 */}
			<Route
				path="/circles"
				exact
				component={Circles}
			/>

{/* 			<Route
				path="/circles-cover"
				exact
				component={CirclesCover}
			/>
 */}
			<Route
				path="/users"
				exact
				component={Users}
			/>

{/* 			<Route
				path="/users-cover"
				exact
				component={UsersCover}
			/>

			<Route
				path="/popups"
				exact
				component={Popups}
			/>
 */}
			<Route
				path="/media"
				exact
				component={Media}
			/>

			{/* <Route
				path="/media-cover"
				exact
				component={MediaCover}
			/>

			<Route
				path="/media-scroll"
				exact
				component={MediaScroll}
			/> */}
		</Switch>
	</Router>
);

export default Routes;