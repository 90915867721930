/**
 * External dependencies.
 */
import React from 'react';

/**
 * Internal dependencies.
 */
import HeaderAlt from 'blocks/header-alt/header-alt';
import Footer from 'blocks/footer/footer';

const LayoutAlt = ({ children, activeNav }) => (
	<div className="wrapper">
		<HeaderAlt activeLink={activeNav} />
		
		{children}

		<Footer />
	</div>
);

export default LayoutAlt;
