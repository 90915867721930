/**
 * External dependencies.
 */
import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

/**
 * Internal dependencies.
 */
import boxImage from 'assets/images/tab-image.png';

const useStyles = makeStyles(theme => createStyles({
	box: {
		position: 'relative',
		padding: '38px 0 0',
	},
	boxHead: {
		position: 'relative',
		marginBottom: '50px',
	},
	boxContent: {
		textAlign: 'center',
		padding: '35px 0',
		'& h4': {
			fontWeight: '600',
			marginBottom: '14px',
			color: '#808191',
		}
	},
	boxLinks: {
		[theme.breakpoints.down('sm')]: {
			whiteSpace: 'nowrap',
			overflowX: 'auto',
		}
	},
	boxLink: {
		position: 'absolute',
		top: '5px',
		right: '-10px',
		[theme.breakpoints.down('md')]: {
			position: 'static',
			margin: '10px 0 0',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px'
		},
		'& .MuiButton-text': {
			[theme.breakpoints.down('xs')]: {
				fontSize: '14px'
			}
		}
	},
	boxButton: {
		width: '172px',
		height: '55px',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '16px',
		lineHeight: '1.13',
		letterSpacing: '.2px',
		background: '#F0F0F3',
		color: '#808191',
		marginRight: '10px',
		fontWeight: '600',
	},
	boxButtonActive: {
		background: '#DBFAFF',
		color: '#0C55ED',
	}
}));


const MediaTabs = ( { modifier, children, isEmpty } ) => {
	const classes = useStyles();

	return (
		<Box className={classes.box + ' ' + modifier}>
			{/* <Box className={classes.boxHead}> // TODO ordenacion y filtrado
				<Box className={classes.boxLinks}>
					<Button 
						variant="contained" 
						className={classes.boxButton + ' ' + classes.boxButtonActive}
					>
						Todos los<br />
						archivos
					</Button>

					<Button 
						variant="contained" 
						className={classes.boxButton}
					>Imágenes</Button>

					<Button 
						variant="contained" 
						className={classes.boxButton}
					>Vídeos</Button>

					<Button 
						variant="contained" 
						className={classes.boxButton}
					>Presentaciones</Button>
				</Box>

				<Box className={classes.boxLink}>
					<Button variant="text">Ordenar por fecha de subida <i className="ico-arrow-up"></i></Button>
				</Box>
			</Box> */}

			<Box className={classes.boxBody}>
				{isEmpty && (
					<Box className={classes.boxContent}>
						<Typography 
							variant="h4" 
							component="h4"
							className={classes.sectionTitle}
						>Aún no has subido ningún archivo.</Typography>

						<img src={boxImage} alt="" />
					</Box>
				)}

				{children}
			</Box>
		</Box>
	);
};

export default MediaTabs;