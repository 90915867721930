/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { getCookie, setCookie } from 'MyUtils';

 const useStyles = makeStyles(theme => createStyles({
	cookies: {
		position: 'fixed',
		bottom: '0',
		left: '0',
		width: '100%',
		background: '#323338',
		color: '#fff',
		boxShadow: '4px 8px 16px rgba(17, 17, 17, 0.04)',
		borderRadius: '16px',
		zIndex: '9999',
		padding: '30px 0',
		transition: 'transform .6s',
		'&__hidden': {
			transform: 'translateY(100%)'
		},
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center'
		}
	},
	cookiesContainer: {
		maxWidth: '1200px',
		position: 'relative',
		paddingRight: '200px',
		margin: 'auto',
		[theme.breakpoints.down('xs')]: {
			paddingRight: '0'
		},
	},
	cookiesText: {
		fontSize: '24px',
		lineHeight: '1.17',
		fontWeight: '500',
		letterSpacing: '0',
		[theme.breakpoints.down('lg')]: {
			fontSize: '18px',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '14px',
		},
		'& a': {
			color: '#00CCEE',
			fontSize: 'inherit',
			fontWeight: 'inherit',
			lineHeight: 'inherit',
			letterSpacing: 'inherit',
		}
	},
	cookiesButton: {
		maxWidth: '141px',
		position: 'absolute',
		top: '50%',
		right: '0',
		transform: 'translateY(-50%)',
		background: 'radial-gradient(100% 100% at 0% 50%, #0C55ED 0%, #00CCEE 100%)',
		padding: '10px 5px',
		fontWeight: '600',
		fontSize: '18px',
		letterSpacing: '0.5px',
		margin: '-5px 0 0',
		[theme.breakpoints.down('xs')]: {
			fontSize: '14px',
			position: 'static',
			transform: 'translateY(0)',
			margin: '15px 0 0',
		}
	},
	cookiesSeparator: {
		[theme.breakpoints.down('sm')]: {
			display: 'none'
		}
	}
 }));

const Cookies = () => {
	const classes = useStyles();
	const [visiblePopup, setVisiblePopup] = useState((getCookie("acceptedCookies")) ? false : true);

	return (
		<Box className={classes.cookies + (!visiblePopup ? ` ${classes.cookies}__hidden` : '')}>
			<Container maxWidth={false}>
				<Box className={classes.cookiesContainer}>
					<Typography component="p" className={classes.cookiesText}>
						Utilizamos cookies para ofrecerte la mejor experiencia en nuestra web. <br className={classes.cookiesSeparator} />
						Puedes aprender más sobre qué cookies utilizamos o desactivarlas en los <Link href="#">ajustes</Link>.
					</Typography>

					<Button 
						className={classes.cookiesButton} 
						variant="contained" 
						color="primary"
						onClick={(e) => { setVisiblePopup(false); setCookie("acceptedCookies", "true", 36500)}}
					>
						Aceptar
					</Button>
				</Box>
			</Container>
		</Box>
	);
};

export default Cookies;
