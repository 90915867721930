/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';

/**
 * Internal dependencies.
 */
import Logo from 'components/logo/logo';
import WidgetProfile from 'components/widget-profile/widget-profile';
import Nav from 'components/nav/nav';
import LogoImage from 'assets/images/logo-dark.svg';
import { useEffect } from 'react';
import { requireAuthToken } from 'MyUtils.js'

const useStyles = makeStyles(theme => createStyles({
	header: {
		background: '#fff',
		boxShadow: '0px 2px 24px rgba(0, 0, 0, 0.1)',
		padding: '22px 0',
		[theme.breakpoints.down('xs')]: {
			padding: '12px 0'
		}
	},
	headerNav: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		marginLeft: '47px',
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0',
			transform: 'rotateX(90deg)',
			transformOrigin: 'top',
			top: '100%',
			left: '0',
			width: '100%',
			background: '#fff',
			boxShadow: '0px 15px 24px rgba(0, 0, 0, 0.1)',
			padding: '22px 12px !important',
			transition: '.4s',
		},
		'&__active': {
			[theme.breakpoints.down('xs')]: {
				transform: 'rotateX(0deg)'
			}
		}
	},
	headerProfile: {
		position: 'absolute',
		top: '50%',
		right: '40px',
		transform: 'translate(0, -50%)',
		[theme.breakpoints.down('lg')]: {
			right: '30px'
		},
		[theme.breakpoints.down('xs')]: {
			right: '12px'
		}
	},
	headerContainer: {
		padding: '0 40px !important',
		[theme.breakpoints.down('lg')]: {
			padding: '0 30px !important',
		}
	},
	headerLogo: {
		[theme.breakpoints.down('xs')]: {
			display: 'block',
			width: '96px',
			margin: 'auto',
		}
	},
	headerMenuButton: {
		display: 'none',
		position: 'absolute',
		top: '50%',
		left: '14px',
		width: '18px',
		height: '12px',
		background: 'none',
		border: 'none',
		transform: 'translate(0, -50%)',
		[theme.breakpoints.down('xs')]: {
			display: 'block'
		},
		'& span': {
			width: '100%',
			height: '2px',
			background: '#808191',
			position: 'absolute',
			left: '0',
			transition: 'top .2s .25s, left .2s .25s, opacity .2s .25s, transform .2s 0s',
			'&:nth-child(1)': {
				top: '0',
			},
			'&:nth-child(2)': {
				top: '5px',
			},
			'&:nth-child(3)': {
				top: '10px',
			}
		},
		'&__active': {
			'& span': {
				transition: 'top .2s, left .2s, opacity .2s, transform .2s .25s',
				'&:nth-child(1)': {
					top: '5px !important',
					transform: 'rotate(45deg)'
				},
				'&:nth-child(2)': {
					opacity: '0'
				},
				'&:nth-child(3)': {
					top: '5px !important',
					transform: 'rotate(-45deg)'
				}
			}
		}
	}
}));

const HeaderAlt = ({activeLink}) => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		async function fetchCircles() {
			await requireAuthToken(true, true);
		};

		fetchCircles();
	}, []);
	return (
		<AppBar position="absolute" className={classes.header}>
			<Container maxWidth={false} className={classes.headerContainer}>
				<Logo modifier={classes.headerLogo}>
					<img src={LogoImage} alt="logo" />
				</Logo>

				<button 
					className={classes.headerMenuButton + (expanded ? ` ${classes.headerMenuButton}__active` : '')}
					onClick={(e) => setExpanded(!expanded)}
				>
					<span></span>
					
					<span></span>
					
					<span></span>
				</button>

				<Nav modifier={classes.headerNav + (expanded ? ` ${classes.headerNav}__active` : '')} activeLink={activeLink} />

				<WidgetProfile modifier={classes.headerProfile} />
			</Container>
		</AppBar>
	);
};

export default HeaderAlt;
