/**
 * External dependencies.
 */
import React, { useState } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Modal from '@mui/material/Modal';

/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';
import ModalMediaNew from 'components/modal-media-new/modal-media-new';
import MediaTabs from 'components/media-tabs/media-tabs';
import ArticleDef from 'components/article-def/article-def';

const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '129px 0 35px',
		[theme.breakpoints.down('md')]: {
			padding: '150px 0 70px',
		},
		[theme.breakpoints.down('xs')]: {
			padding: '100px 0 70px',
		},
	},
	sectionBar: {
		textAlign: 'center',
		marginBottom: '4px',
		[theme.breakpoints.down('xs')]: {
			position: 'fixed',
			bottom: '97px',
			right: '19px',
			marginBottom: '0',
			zIndex: '100',
		}
	},
	sectionButton: {
		[theme.breakpoints.down('xs')]: {
			fontSize: '0',
			minWidth: '0',
			width: '56px',
			height: '56px',
			padding: '0',
			borderRadius: '50%',
		},
		'& i': {
			display: 'none',
			position: 'absolute',
			top: '50%',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			[theme.breakpoints.down('xs')]: {
				display: 'inline-block',
			},
		}

	}


}));

const articles = [
	{
		image: require('assets/images/media.jpg'),
		title: "Presentación La Front...",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media1.jpg'),
		title: "PresentacionLFVR-v1",
		type: "pdf",
		info: "PDF · 2,8 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "image",
		info: "PNG · 129 KB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	},
	{
		image: require('assets/images/media.jpg'),
		title: "LaFrontera_VR",
		type: "mp4",
		info: "MP4 · 8,2 MB",
		url: "/"
	}
]

const MediaScroll = () => {
	const classes = useStyles();
	const [openModal, setOpenModal] = useState(false);

	return (
		<LayoutAlt activeNav="users">
			<Box className={classes.section}>
				<Box>
					<Container maxWidth="lg">
						<Box className={classes.sectionBar}>
							<Button 
								variant="contained" 
								color="secondary" 
								className={classes.sectionButton}
								onClick={(e) => { 
									e.preventDefault()

									setOpenModal(!openModal) 
								}}
							>
								Subir archivo

								<i className="ico-plus"></i>
							</Button>
						</Box>

						<MediaTabs>
							<Grid container spacing="3">
								{articles?.map((article, index) => {
									return (
										<Grid key={index} item xl={3} lg={3} md={4} sm={6} xs={12}>
											<ArticleDef
												image={article.image}
												title={article.title}
												type={article.type}
												info={article.info}
												url={article.url}
												skeleton={article.skeleton}
											/>
										</Grid>
									) 
								})}
							</Grid>
						</MediaTabs>

					</Container>
				</Box>
			</Box>
			
			<Modal
				open={openModal}
				onClose={(e) => { setOpenModal(false) }}
			>
				<div><ModalMediaNew onClose={(e) => { setOpenModal(false) }} /></div>
			</Modal>
		</LayoutAlt>
	);
};

export default MediaScroll;
