/**
 * External dependencies.
 */
import React, { useState, useEffect } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Modal from '@mui/material/Modal';

/**
 * Internal dependencies.
 */
import LayoutAlt from 'layouts/layout-alt/layout-alt';
import WidgetInfo from 'components/widget-info/widget-info';
import MediaTabs from 'components/media-tabs/media-tabs';
import ArticleDef from 'components/article-def/article-def';
import ModalMediaEdit from 'components/modal-media-edit/modal-media-edit';

import { fetchMedia, getAppMetadataValue, getProfileData, requireUserID } from 'MyUtils.js'
import boxImage from 'assets/images/placeholder2.jpg';
import placeholderSlides from 'assets/images/placeholderSlides.jpg';

const useStyles = makeStyles(theme => createStyles({
	section: {
		position: 'relative',
		zIndex: '10',
		height: 'calc(100vh - 64px)',
		overflowY: 'auto',
		overflowX: 'hidden',
		padding: '159px 0 17px',
		[theme.breakpoints.down('md')]: {
			
		},
		[theme.breakpoints.down('xs')]: {
			
		}
	},
	
}));

const Media = () => {
	const classes = useStyles();

	const [openModal, setOpenModal] = useState(false);
	const [currentName, setCurrentName] = useState("Loading");
	const [currentSrc, setCurrentSrc] = useState("Loading");
	const [currentType, setCurrentType] = useState("Loading");
	const [articles, setArticles] = useState(() => {
		let aux = [];
		for (let i = 0; i < 10; i++) {
			aux.push({ 
				image: 'loading.jpg',
				title: "loading",
				type: "loading",
				info: "loading",
				url: "loading",
				skeleton: true
			});
		}
		return aux;
	});

	useEffect(()=>{
		async function loadMedia() {
			let collection = "";
			try {
				collection = getAppMetadataValue(await getProfileData(requireUserID(true)), "Creator");
			} catch (error) {
				console.error(error);
			}
			const data = await fetchMedia(collection);

			//console.log(data)
			
			let auxArticles = [];

			if (data.MediaFiles.length > 0) {
				data.MediaFiles.forEach(element => {
					auxArticles.push({
						image: element.ContentType.startsWith('image') ? element.DownloadThumbnailUrl : boxImage,
						title: element.FileName,
						type: element.ContentType,
						info: element.ContentType, // TODO show file size
						url: element.DownloadUrl
					});
				});
			}

			// Now fetch slides
			const dataSlides = await fetchMedia(collection + "/slides");

			//console.log(dataSlides)

			if (dataSlides.MediaSubCollections.length > 0) {
				dataSlides.MediaSubCollections.forEach(element => {
					auxArticles.push({
						image: placeholderSlides,
						title: element.CollectionName,
						type: "text/pdf",
						info: "text/pdf", // TODO show file size
						url: "#"
					});
				});
			}

			setArticles(auxArticles);				
		};

		loadMedia();
	}, [])

	return (
		<LayoutAlt>
			<Box className={classes.section}>
				<Container maxWidth="lg">
					<WidgetInfo />

					<MediaTabs isEmpty={articles.length <= 0}>
						<Grid container spacing="3">
							{articles?.map((article, index) => {
								return (
									<Grid key={index} item xl={3} lg={3} md={4} sm={6} xs={12}>
										<ArticleDef
											image={article.image}
											title={article.title.split("/")[article.title.split("/").length - 1]
											}
											type={article.type}
											info={article.info}
											url={article.url}
											skeleton={article.skeleton}
											openModal={(e) => {setOpenModal(true);
												setCurrentName(() => {
													let splitTitle = article.title.split("/");
													return splitTitle[splitTitle.length - 1];
												});
												setCurrentSrc(article.url);
												setCurrentType(article.type)
											}}
										/>
									</Grid>
								) 
							})}
						</Grid>
					</MediaTabs>
	
				</Container>
			</Box>
			<Modal
				open={openModal}
				onClose={(e) => { setOpenModal(false) }}
			>
				<div><ModalMediaEdit title={currentName} url={currentSrc} type={currentType} onClose={(e) => { setOpenModal(false) }} /></div>
			</Modal>

		</LayoutAlt>
	);
};

export default Media;
